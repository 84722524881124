<template>
    <div>
        <button v-show="!showChat" @click="openChat" type="button" class="open-button btn btn-primary custom-shadow">
            <i class="far fa-comment"></i> <span v-if="notRead > 0" class="badge badge-light">{{notRead}}</span>
        </button>
        <div v-show="showChat" class="chat-popup custom-shadow">
            <div class="header p-1">
              <h5 class="text-white">
                  Chat interno
                  <button @click="showChat = false;showEmojiPicker = false" class="btn set-pointer text-white">
                    <i class="far fa-times-circle header-close-button"></i>
                  </button>
              </h5>
            </div>
            <div class="form-container">
              <div class="message-box" ref="messageBox">
                  <button v-show="hasMoreMessages" @click="loadMessages" class="btn btn-success load-button custom-shadow"><i class="fas fa-sync-alt"></i></button>
                  <div
                      class="my-2 px-3 custom-shadow" v-for="(msg, i) in messages" :key="i" 
                      :class="{'message-self': msg.senderId == senderId, 'message-external': msg.senderId != senderId}">
                      <strong class="message-sender">{{chechUserSender(msg.senderId, msg.sender)}}</strong>
                      <span class="message-date">{{msg.formatDate}}</span>
                      <br>
                      <span class="message-text">{{msg.text}}</span>
                      <br>
                  </div>
              </div>
              <span class="text-muted is-writing">{{isWritingSpan}}</span>
              <div class="input-group">
                  <input @input="isWriting" @keyup.enter="submitMessage" ref="chatBox" v-model="chatText" type="text" class="form-control">
                  <div @click="showEmojiPicker = !showEmojiPicker" class="input-group-append set-pointer">
                    <span class="input-group-text" id="addon-wrapping">
                      <i class="far fa-laugh"></i>
                    </span>
                  </div>
                  <div class="input-group-append">
                    <button @click="submitMessage" class="btn" type="button"><i class="text-white far fa-paper-plane"></i></button>
                  </div>
              </div>
            </div>
        </div>
        <div class="emoji-picker-popup" v-if="showEmojiPicker">
          <div class="emoji-picker">
            <span @click="selectEmoji(emoji)" v-for="(emoji, i) in emojiData" :key="i" class="set-pointer">{{emoji}}</span>
          </div>
        </div>
    </div>
</template>

<script>
import { Message } from '@/assets/utils/models/message_model.js'
import emojiData from '@/assets/emojiData.js'

import axios from 'axios'

export default {
    data(){
        return {
            emojiData,
            showEmojiPicker: false,
            chatText: '',
            messages: [],
            showChat: false,
            notRead: 0,
            isWritingSpan: '',
            actualPage: 0,
            hasMoreMessages: true
        }
    },
    sockets: {
        'chat@message-received'(data){
            this.$toasted.success('Mensaje recibido')
            this.messages.push(new Message(data.text, data.date, data.sender, data.senderId))
            if(this.showChat == false) this.notRead++
            else this.notRead = 0
            this.goBottom()
        },
        'chat@user-writing'(data){
            this.isWritingSpan = data.active ? `${data.sender} esta escribiendo` : ''
        }
    },
    methods: {
        async loadMessages(){
          this.actualPage++
          const resp = await axios.get(`/chat/messages?page=${this.actualPage}`)
          if(!resp.data.hasMoreMessages) return this.hasMoreMessages = false
          const oldScrollPosition = this.$refs.messageBox.scrollHeight
          for(let message of resp.data.messages)
            this.messages.unshift(new Message(message.text, message.date, message.sender, message.senderId)) 
          this.$nextTick(() => {
            this.$refs.messageBox.scrollTop = this.$refs.messageBox.scrollHeight - oldScrollPosition
          })
        },
        selectEmoji(emoji){
          this.chatText += emoji;
          this.showEmojiPicker = false;
          this.setInputFocus()
        },
        chechUserSender(senderId, senderName){
          return senderId == this.senderId ? 'Yo' : senderName 
        },
        isWriting(){
          this.$socket.emit('chat@is-writing', {sender: this.sender, active: this.chatText != ''})
        },
        goBottom(){
            const messageBox = this.$refs.messageBox
            this.$nextTick(() => {
              messageBox.scrollTop = messageBox.scrollHeight - messageBox.clientHeight
            })
        },
        setInputFocus(){
            this.$nextTick(() => {
              this.$refs.chatBox.focus()
            })
        },
        openChat(){
            this.showChat = true
            this.goBottom()
            this.notRead = 0
            this.setInputFocus()
        },
        submitMessage(){
            if(this.chatText == '') return;
            const message = new Message(this.chatText, null, this.sender, this.senderId)
            this.$socket.emit('chat@message-send', message)
            this.$socket.emit('chat@is-writing', {sender: this.sender, active: false})
            this.chatText = ''
            this.messages.push(message)
            this.goBottom()
        }
    },
    async created(){
      await this.loadMessages()
    },
    props: {
        sender: String,
        senderId: String,
        show: Boolean
    }
}
</script>

<style lang="scss" scoped>
.header{
  background-color: #55e255;
}

.header-close-button{
  font-size: 1.5rem;
  margin-left: 170px;
}

.btn{
  background-color: #55e255;  
}

.emoji-picker-popup {
  position: fixed;
  width: 17%;
  max-height: 20%;
  bottom: 50px;
  right: 50px;
  border: 3px solid #f1f1f1;
  z-index: 1000;
  overflow: auto;
}

.emoji-picker{
  max-width: 400px;
  padding: 10px;
  background-color: white;
}

.external-message {
  background-color: #55e255;
}

.is-writing {
  font-size: 0.8em
}

.load-button{
  border-radius: 50%;
  margin: 0 9em auto;
}

.message-self{
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  padding: 5px;
  max-width: 66%;
  border-radius: 1.125rem 1.125rem 0 1.125rem;
  margin: 1rem 1rem 1rem auto ;
}
.message-external{
  background-color: #9eff9e;
  box-sizing: border-box;
  width: 100%;
  padding: 5px;
  max-width: 66%;
  border-radius: 1.125rem 1.125rem 1.125rem 0;
  margin: 1rem 1rem auto 1rem;
}

.message-sender{
  font-size: 0.8em
}

.message-text{
  font-size: 0.9em;
  margin-left: 15px;
  word-wrap:break-word;
}

.message-date{
  font-size: 0.7em;
  margin-left: 5px;
}

/* Button used to open the chat form - fixed at the bottom of the page */
.open-button {
  background-color: #555;
  color: white;
  padding: 16px;
  border: none;
  cursor: pointer;
  opacity: 1;
  position: fixed;
  bottom: 23px;
  right: 28px;
  width: 60px;
  border-radius: 50%;
}

/* The popup chat - hidden by default */
.chat-popup {
  position: fixed;
  bottom: 15px;
  right: 20px;
  z-index: 1000;
}

/* Add styles to the form container */
.form-container {
  max-width: 400px;
  background-color: white;
  display: flex;
  flex-direction: column;
}

/* Full-width textarea */
.message-box {
  width: 350px;
  height: 300px;
  padding: 5px;
  margin: 0 0 22px 0;
  border: none;
  flex-shrink: 1;
  background: #f1f1f1;
  overflow: auto;
  min-height: 200px;
}

.message-box::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: #F5F5F5;
}

.message-box::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

.message-box::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: #32CD32;
}

.emoji-picker-popup::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: #F5F5F5;
}

.emoji-picker-popup::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

.emoji-picker-popup::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: #32CD32;
}
</style>