<template>
    <div class="admin-home">
        <itc-admin-sidebar></itc-admin-sidebar>
        <div class="main">
          <transition v-if="isLoaded" name="slide-left" mode="out-in">
            <router-view/>
          </transition>
        </div>
        <itc-floating-chat :sender="$store.getters.getUserName"></itc-floating-chat>
    </div>
</template>

<script>
import Navigation from '../components/shared/admin/Navigation'
import AdminSideBar from '../components/shared/admin/AdminSideBar'
import FloatingChat from '../components/shared/chat/FloatingChat'

export default {
  data(){
    return {
      isLoaded: false
    }
  },
  components: {
    'itc-admin-navigation': Navigation,
    'itc-admin-sidebar': AdminSideBar,
    'itc-floating-chat': FloatingChat
  },
  async created(){
    this.$store.dispatch('httpGetDollarValue')
      .then(() => {
        this.isLoaded = true
      })
  }
}
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  width: 82%;
  top: 0;
  left: 16%;
}


.slide-left-enter-active,
.slide-left-leave-active{
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter{
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active{
  opacity: 0;
  transform: translate(-2em, 0);
}
</style>
