const { format } = require('timeago.js')

export class Message{
    constructor(text, date, sender, senderId){
        this.text = text;
        this.sender = sender;
        this.senderId = senderId;
        this.date = date || new Date()
    }

    get formatDate(){
        return format(this.date)
    }

};