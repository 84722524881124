<template>
  <div class="admin-sidebar">
    <itc-sidebar imgLink="/shop">
      <router-link to="/admin/dashboard"><span class="sidebar-item p-3">Dashboard<i class="sidebar-icon fas fa-chart-line"></i></span></router-link>
      <router-link to="/admin/products"><span class="sidebar-item p-3">Productos<i class="sidebar-icon fas fa-tags"></i></span></router-link>
      <router-link to="/admin/e-commerce"><span class="sidebar-item p-3">E-commerce<i class="sidebar-icon fas fa-store-alt"></i></span></router-link>
      <router-link to="/admin/orders"><span class="sidebar-item p-3">Ordenes<i class="sidebar-icon fas fa-file-invoice"></i></span></router-link>
      <router-link to="/admin/cash-registers"><span class="sidebar-item p-3">Cajas diarias<i class="sidebar-icon fas fa-cash-register"></i></span></router-link>
      <router-link to="/admin/categories"><span class="sidebar-item p-3">Categorías<i class="sidebar-icon far fa-folder-open"></i></span></router-link>
      <router-link to="/admin/providers"><span class="sidebar-item p-3">Proveedores<i class="sidebar-icon fas fa-truck-loading"></i></span></router-link>
      <router-link to="/admin/cards"><span class="sidebar-item p-3">Tarjetas<i class="sidebar-icon fas fa-credit-card"></i></span></router-link>
      <div class="input-group dollar-block p-3">
        <div class="input-group-prepend">
            <span class="input-group-text">Dólar: $</span>
        </div>
        <input ref="dollarInput" type="number" class="form-control" step="0.0001" v-model="actualDollar" :disabled="disableInput">
        <div class="input-group-append">
            <button @click="editDollarValue" class="btn" :class="{'btn-info': disableInput, 'btn-success': !disableInput}" type="button">
                <i class="far text-white mx-1 set-pointer" :class="{'fa-edit': disableInput, 'fa-check-circle': !disableInput}"></i>    
            </button>
        </div>
      </div>
    </itc-sidebar>
  </div>
</template>

<script>
import SideBar from '@/components/mix/SideBar'

import { mapState } from 'vuex'

export default {
  data(){
    return {
      actualDollar: 0,
      disableInput: true
    }
  },
  computed: mapState(['dollar']),
  watch: {
    dollar(n, o){
      this.actualDollar = n
    }
  },
  methods: {
    editDollarValue(){
      if(!this.disableInput){
        this.$store.dispatch('postDollarValue', this.actualDollar)
        this.$toasted.success('Dólar actualizado')
      }
      this.disableInput = !this.disableInput
      this.$nextTick(() => {
        this.$refs.dollarInput.focus()
      })
    }
  },
  components: {
    'itc-sidebar': SideBar
  },
}
</script>

<style lang="scss" scoped>
.sidebar-item{
  display: inline-block;
  color: #fff;
  font-size: 15px;
  margin-left: 40px;
}

.dollar-block{
  border-bottom: 1px solid transparentize($color: #D3D3D3, $amount: 0.9)
}

i.sidebar-icon {
  margin: 0px 20px 0px 20px
}
</style>