<template>
    <div class="sidebar custom-shadow">
        <div class="sidenav">
            <router-link :to="imgLink || ''">
              <div class="logo text-center">
                <img id="navImg" src="../../assets/itcentro-logos/itcentro-logo.png">
              </div>
            </router-link>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    imgLink: String
  }
}
</script>

<style lang="scss" scoped>
.logo{
    background-color: #D3D3D3;
    padding: 10px
}

img#navImg {
    width: 150px;
    height: 40px;
}

.sidenav{
    height: 100%;
    width: 15%; 
    position: fixed;
    z-index: 1000;
    bottom: 0;
    left: 0;
    background-color: #333; /* Black */
    overflow-x: hidden;
}

.sidenav a {
  list-style: none;
  text-decoration: none;
  display: block;
  border-bottom: 1px solid transparentize($color: #D3D3D3, $amount: 0.9)
}

.sidenav a:hover {
  background-color: #339933;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}
</style>